import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

export const BoilerControl = ({
  longDescription,
  name,
  photo,
  price,
  selected,
  shortDescription,
  selectBoilerControl,
}) => {
  const ReadMoreText = React.useRef(null)
  const [readMoreTextVisible, setReadMoreTextVisible] = React.useState(false)

  const toggleReadMoreText = () => {
    const ReadMoreTextElement = ReadMoreText.current
    const ReadMoreTextHeight = ReadMoreTextElement.scrollHeight

    if (ReadMoreTextElement.style.maxHeight) {
      ReadMoreTextElement.style.maxHeight = null
      setReadMoreTextVisible(false)
    } else {
      ReadMoreTextElement.style.maxHeight = ReadMoreTextHeight + "px"
      setReadMoreTextVisible(true)
    }
  }

  return (
    <div className={`boiler-control${selected ? " selected" : ""}`}>
      <div className="boiler-control-body">
        <section>
          <Img
            className="boiler-control-photo"
            fluid={photo}
            alt={name + " boiler control"}
            title={name + " boiler control"}
          />
        </section>
        <section>
          <h3 className="heading-small">{name}</h3>
          <p className="boiler-control-info">{shortDescription}</p>
          <button
            className={`boiler-control-read-more-button${
              readMoreTextVisible ? " active" : ""
            }`}
            onClick={toggleReadMoreText}
          >
            Read more <span className="icon-wedge"></span>
          </button>
          <div className="boiler-control-read-more-text" ref={ReadMoreText}>
            <ReactMarkdown source={longDescription} />
          </div>
        </section>
      </div>
      <button
        onClick={selectBoilerControl}
        className="button-primary green boiler-control-button"
      >
        {selected && <span className="icon-tick"></span>}
        {price === 0 ? "FREE" : "£" + price}
      </button>
    </div>
  )
}

BoilerControl.propTypes = {
  id: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  photo: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  shortDescription: PropTypes.string.isRequired,
}
