import React from "react"

import { BreakDownTable } from "../../../Shared"
import { StripeSubscriptionForm } from "../StripeSubscriptionForm"
import {
  convertPriceToPounds,
  calculateOrderPayments,
} from "../../../../utilities"

export const PayHalfUpFront = ({
  adminLineItems,
  boilerAmount,
  controlAmount,
  flueCost,
  labourCost,
  orderId,
  setOrderCompleted,
  updateOrder,
  vat,
}) => {
  const boilerHalfUpfront = calculateOrderPayments({
    boilerAmount: parseInt(boilerAmount) * 100,
    boilerControlAmount: parseInt(controlAmount) * 100,
    code: "50-percent-upfront",
    financeMonths: 12,
    interestRate: 0,
    labourCost: parseInt(labourCost) * 100,
    flueCost: parseInt(flueCost) * 100,
    adminLineItems: adminLineItems,
    vat: vat,
  })

  return (
    <>
      <p>
        Pay for 50% of your boiler now and the rest with 12 monthly instalments
        (0% APR Representative).
      </p>
      <p>Payments will be taken on the date of purchase each month.</p>
      <BreakDownTable
        rows={[
          {
            id: "1",
            label: "Pay now",
            price: convertPriceToPounds(boilerHalfUpfront.upfrontAmount),
          },
          {
            id: "2",
            label: "Loan amount",
            price: convertPriceToPounds(boilerHalfUpfront.financeAmount),
          },
          {
            id: "3",
            label: "Interest",
            price: 0,
          },
          {
            id: "4",
            label: "(Monthly payment)",
            price: convertPriceToPounds(boilerHalfUpfront.monthlyRepayment),
          },
          {
            id: "5",
            label: "Total",
            price: convertPriceToPounds(boilerHalfUpfront.totalRepayment),
          },
        ]}
      />
      <StripeSubscriptionForm
        paymentCode="50-percent-upfront"
        orderId={orderId}
        setOrderCompleted={setOrderCompleted}
        updateOrder={updateOrder}
        financeData={boilerHalfUpfront}
      />
    </>
  )
}
