import React from "react"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"

import { AddressLookup, SnackBarOptions } from "../../Shared"
import { handleBackendErrors } from "../../../utilities"

export const InstallAddress = ({ updateSteps, updateOrder, order }) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)

  const saveUserAddress = async (
    values,
    setSubmitting,
    existingData,
    updateExistingData
  ) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.GATSBY_API_BASE_URL}/order/add-user-details`,
        data: {
          orderId: existingData.orderId,
          userDetails: {
            firstName: existingData.steps.details.data.firstName,
            lastName: existingData.steps.details.data.lastName,
            email: existingData.steps.details.data.email,
            mobile: existingData.steps.details.data.mobile,
            homePhone: existingData.steps.details.data.homePhone,
            address: values,
          },
        },
      })
      updateExistingData((order) => ({
        ...order,
        steps: {
          ...order.steps,
          installAddress: {
            ...order.steps.installAddress,
            result: values.line1,
            data: values,
          },
        },
      }))
      updateSteps()
    } catch (error) {
      openSnackbar(handleBackendErrors(error, "save address"))
    }
    setSubmitting(false)
  }

  return (
    <AddressLookup
      successFunc={saveUserAddress}
      existingData={order}
      updateExistingData={updateOrder}
      nextButtonText="Upload photos (optional)"
    />
  )
}
