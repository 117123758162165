import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Calendar from "react-calendar"
import dayjs from "dayjs"
import { useSnackbar } from "react-simple-snackbar"

import { ButtonWithLoader, SnackBarOptions } from "../../Shared"
import {
  convertToDateWithSuffix,
  handleBackendErrors,
} from "../../../utilities"

import CalendarChevron from "../../../images/date-picker-chevron.svg"

export const InstallDate = ({ order, updateOrder, updateSteps }) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const [date, setDate] = React.useState(null)
  const [error, setError] = React.useState("")
  const [monthYear, setMonthYear] = React.useState({
    month: dayjs().format("M"),
    year: dayjs().format("YYYY"),
  })
  const [isLoading, setIsLoading] = React.useState(true)
  const [saveIsLoading, setSaveIsLoading] = React.useState(false)
  const [availableDates, setAvailableDates] = React.useState([])
  const [monthView, setMonthView] = React.useState(new Date())

  const getAvailableDates = async () => {
    try {
      const availableDates = await axios({
        method: "post",
        url: `${process.env.GATSBY_API_BASE_URL}/install-dates/available-dates`,
        data: monthYear,
      })
      const dates = availableDates.data.availableISODates
      setAvailableDates(dates)
      if (date === null) {
        // Initial load
        if (dates.length > 0) {
          setDate(dayjs(dates[0]).toDate())
        } else {
          // No available dates
          const followingMonth = dayjs(monthView).add(1, "month").toDate()
          setMonthView(followingMonth)
          setMonthYear({
            month: dayjs(followingMonth).format("M"),
            year: dayjs(followingMonth).format("YYYY"),
          })
        }
      }
    } catch (error) {
      openSnackbar(handleBackendErrors(error, "find available dates"))
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 100)
  }

  const changeViewMonth = (date) => {
    setIsLoading(true)
    setMonthView(dayjs(date.activeStartDate).toDate())
    setMonthYear({
      month: dayjs(date.activeStartDate).format("M"),
      year: dayjs(date.activeStartDate).format("YYYY"),
    })
  }

  const checkIfUnavailable = (date) => {
    const day = dayjs(date).toISOString()

    const isUnavailable = !availableDates.find((x) =>
      dayjs(x).isSame(day, "day")
    )

    return isUnavailable
  }

  const selectDate = (date) => {
    setDate(date)
    error !== "" && setError("")
  }

  const saveSelectedDate = async () => {
    if (date === undefined) {
      setError("Please pick your install date before continuing")
    } else {
      setSaveIsLoading(true)
      try {
        await axios({
          method: "post",
          url: `${process.env.GATSBY_API_BASE_URL}/order/select-date`,
          data: {
            orderId: order.orderId,
            installDate: dayjs(date).toISOString(),
          },
        })
        updateOrder((order) => ({
          ...order,
          steps: {
            ...order.steps,
            installDate: {
              ...order.steps.installDate,
              result:
                convertToDateWithSuffix(dayjs(date).format("D")) +
                " " +
                dayjs(date).format("MMM"),
              data: {
                installDate: dayjs(date).toISOString(),
              },
            },
          },
        }))
        updateSteps()
        setSaveIsLoading(false)
      } catch (error) {
        openSnackbar(handleBackendErrors(error, "save date"))
        setSaveIsLoading(false)
      }
    }
  }

  React.useEffect(() => {
    getAvailableDates()
  }, [monthYear])

  return (
    <div className="install-date">
      <div className="install-date-calendar">
        <Calendar
          activeStartDate={monthView}
          onActiveStartDateChange={(value) => changeViewMonth(value)}
          onChange={selectDate}
          className={isLoading ? "" : "loaded"}
          value={date}
          view="month"
          prevLabel={<CalendarChevron />}
          nextLabel={<CalendarChevron />}
          showFixedNumberOfWeeks={true}
          tileDisabled={({ date }) => checkIfUnavailable(date)}
        />
        <span
          className={`install-date-calendar-loader${
            isLoading ? "" : " loaded"
          }`}
        />
      </div>
      {error ? <span className="install-date-error">{error}</span> : ""}
      <ButtonWithLoader
        additionalClasses="button-checkout"
        onClick={() => saveSelectedDate()}
        disabled={saveIsLoading}
      >
        Choose your controls
      </ButtonWithLoader>
    </div>
  )
}

InstallDate.propTypes = {
  updateOrder: PropTypes.func.isRequired,
  updateSteps: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
}
