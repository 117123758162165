import React from "react"
import PropTypes from "prop-types"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import axios from "axios"

import { BoilerOrderContext } from "../../"
import { ButtonWithLoaderPayment, Modal } from "../../../Shared"
import { stripeOptions } from ".."
import { convertPriceToPounds } from "../../../../utilities"

export const StripeSubscriptionForm = ({
  paymentCode,
  financeMonths,
  orderId,
  setOrderCompleted,
  financeData,
  updateOrder,
}) => {
  const order = React.useContext(BoilerOrderContext)
  const [error, setError] = React.useState(null)
  const [birthDateModalVisible, setBirthDateModalVisible] = React.useState(
    false
  )
  const [birthDate, setBirthDate] = React.useState({
    value: "",
    valid: false,
    error: null,
  })
  const [disabled, setDisabled] = React.useState(true)
  const [processing, setProcessing] = React.useState(false)
  const [succeeded, setSucceeded] = React.useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [retrySubscription, setRetrySubscription] = React.useState(false)
  const [
    retrySubscriptionInvoiceId,
    setRetrySubscriptionInvoiceId,
  ] = React.useState(false)

  // Google Analytics conversion rate tracking
  React.useEffect(() => {
    window.dataLayer = window.dataLayer || []
  }, [])

  const completeGoogleAnalyticsConversion = async () => {
    try {
      dataLayer.push({
        event: "purchase",
        transactionId: order.orderId,
        transactionAffiliation: "HomeAssist.Services Ltd",
        transactionTotal: convertPriceToPounds(financeData.totalRepayment),
        transactionTax: 0,
        transactionShipping: 0,
        transactionProducts: [
          {
            sku: order.boilerId,
            name: order.boiler.name,
            category: "Boiler",
            price: order.boiler.price,
            quantity: 1,
          },
          {
            name: "Labour Cost",
            category: "Labour Cost",
            price: order.quote.labourCost,
            quantity: 1,
          },
          {
            sku: order.steps.controls.data.id,
            name: order.steps.controls.data.name,
            category: "Controls",
            price: order.steps.controls.data.price,
            quantity: 1,
          },
          {
            name: "Boiler Flue",
            category: "Boiler Flue",
            price: order.quote.flueCost,
            quantity: 1,
          },
        ],
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = async (event) => {
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }

  const handleSubmit = async (ev, isRetry) => {
    ev.preventDefault()
    if (birthDate.valid) {
      setProcessing(true)
      stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        })
        .then((result) => {
          if (result.error) {
            setError(
              "Error setting up subscription, please contact customer support on 0191 406 0888"
            )
            setProcessing(false)
          } else {
            createSubscription(result.paymentMethod.id, isRetry)
          }
        })
    } else {
      setBirthDate((curBirthDate) => ({
        ...curBirthDate,
        error: "Please enter a valid date in the format dd/mm/yyyy",
      }))
    }
  }

  const createSubscription = async (paymentMethodId, isRetry) => {
    let data

    if (isRetry) {
      data = {
        orderId: orderId,
        paymentMethodId: paymentMethodId,
        invoiceId: retrySubscriptionInvoiceId,
      }
    } else {
      data = {
        orderId: orderId,
        paymentMethodId: paymentMethodId,
        financeMonths: financeMonths,
        paymentCode: paymentCode,
        birthDate: birthDate.value,
        // grossAnnualIncome: "60000",
      }
    }

    try {
      const request = await axios({
        method: "post",
        url:
          process.env.GATSBY_API_BASE_URL +
          (isRetry ? "/order/retry-invoice" : "/order/create-subscription"),
        data: data,
      })

      if (isRetry) {
        const response = request.data.invoice

        handleSubscriptionError(
          response.payment_intent,
          response.id,
          paymentMethodId
        )
      } else {
        const response = request.data.subscription

        if (response.status === "active" || response.status === "trialing") {
          setProcessing(false)
          setError("")
          setSucceeded(true)
          updateOrder((order) => ({
            ...order,
            steps: {
              ...order.steps,
              payment: {
                ...order.steps.payment,
                data: {
                  ...financeData,
                },
              },
            },
          }))
          localStorage.removeItem("orderId")
          localStorage.removeItem("orderNumber")
          await completeGoogleAnalyticsConversion()
          setOrderCompleted(true)
        } else {
          /* responseStatus === "incomplete" */
          handleSubscriptionError(
            response.latest_invoice.payment_intent,
            response.latest_invoice.id,
            paymentMethodId
          )
        }
      }
    } catch (error) {
      console.log(error.response)
      setError("Payment card failed, please use another card")
      setProcessing(false)
    }
  }

  const handleSubscriptionError = async (
    paymentIntent,
    invoiceId,
    paymentMethodId
  ) => {
    if (
      paymentIntent.status === "requires_action" ||
      "requires_payment_method"
    ) {
      try {
        const result = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: paymentMethodId,
          }
        )

        if (result.error) {
          setRetrySubscription(true)
          setRetrySubscriptionInvoiceId(invoiceId)
          setError("Authentication failed, please retry or use another card")
          setProcessing(false)
        } else {
          if (result.paymentIntent.status === "succeeded") {
            localStorage.removeItem("orderId")
            localStorage.removeItem("orderNumber")
            setProcessing(false)
            setError("")
            setSucceeded(true)
            updateOrder((order) => ({
              ...order,
              steps: {
                ...order.steps,
                payment: {
                  ...order.steps.payment,
                  data: {
                    ...financeData,
                  },
                },
              },
            }))
            await completeGoogleAnalyticsConversion()
            setOrderCompleted(true)
          } else {
            setRetrySubscription(true)
            setRetrySubscriptionInvoiceId(invoiceId)
            setError("Payment card failed, please retry or use another card")
            setProcessing(false)
          }
        }
      } catch (error) {
        setRetrySubscription(true)
        setRetrySubscriptionInvoiceId(invoiceId)
        setError("Payment card failed, please use another card")
        setProcessing(false)
      }
    } else {
      setRetrySubscription(true)
      setRetrySubscriptionInvoiceId(invoiceId)
      setError("Payment card failed, please use another card")
      setProcessing(false)
    }
  }

  const updateBirthDate = (date) => {
    const dobRegex = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g

    if (dobRegex.test(date)) {
      setBirthDate({
        value: date,
        valid: true,
        error: null,
      })
    } else {
      setBirthDate((curBirthDate) => ({
        ...curBirthDate,
        value: date,
        valid: false,
      }))
    }
  }

  return (
    <form
      className="payment-form payment-form-subscription"
      onSubmit={(e) => handleSubmit(e, retrySubscription)}
    >
      <CardElement
        id="card-element"
        options={stripeOptions}
        onChange={handleChange}
      />
      <div
        className={`input payment-form-subscription-dob${
          birthDate.error ? " form-error" : ""
        }`}
      >
        <label className="label label-no-optional" htmlFor="birthDate">
          <span className="payment-form-subscription-dob-text">
            Your Date of Birth
          </span>
          <button
            className="payment-form-subscription-dob-button"
            aria-label="Why we collect your date of birth"
            onClick={() => setBirthDateModalVisible(true)}
            type="button"
          >
            <span className="icon-info" />
          </button>
        </label>
        <Modal
          heading="Why we collect your date of birth"
          isVisible={birthDateModalVisible}
          dismissFunc={() => setBirthDateModalVisible(false)}
          size="medium"
        >
          <div className="rich-text payment-form-subscription-dob-modal">
            <ul>
              <li>
                <p>
                  Your Date of Birth is required to set up a subscription
                  payment because we need to verify your identity and run a
                  credit check to determine whether you can afford your boiler
                  repayment plan.
                </p>
              </li>
              <li>
                <p>
                  If you fail our credit check, we'll refund any payments
                  already made in full.
                </p>
              </li>
            </ul>
          </div>
        </Modal>
        <input
          className="input-field"
          type="text"
          name="birthDate"
          id="birthDate"
          placeholder="dd/mm/yyyy"
          value={birthDate.value}
          onChange={(e) => updateBirthDate(e.target.value)}
        />
      </div>
      {error || birthDate.error ? (
        <span className="form-error-text">
          {error} {birthDate.error}
        </span>
      ) : null}
      <ButtonWithLoaderPayment
        isLoading={processing}
        disabled={processing || disabled || succeeded}
        type="submit"
        id="submit"
      >
        <span className="icon-padlock"></span>
        <span className="payment-option-pay-button">Pay now</span>
      </ButtonWithLoaderPayment>
    </form>
  )
}

StripeSubscriptionForm.defaultProps = {
  financeMonths: "12",
}

StripeSubscriptionForm.propTypes = {
  financeMonths: PropTypes.string,
  orderId: PropTypes.string.isRequired,
  paymentCode: PropTypes.string.isRequired,
  financeData: PropTypes.object.isRequired,
}
