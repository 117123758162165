import React from "react"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"

import { ButtonWithLoader, SnackBarOptions } from "../../Shared"
import { handleBackendErrors } from "../../../utilities"
import { BoilerControl } from "./BoilerControl"

export const Controls = ({
  boilerControls,
  updateSteps,
  updateOrder,
  order,
}) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const standardControl = boilerControls.find(({ node }) => node.contentful_id)
    .node
  const [selectedControl, setSelectedControl] = React.useState({
    id: "uZuW9pU0KrZ5LtJBHm8oZ",
    name: standardControl.name,
    price: standardControl.price,
  })
  const [loading, isLoading] = React.useState(false)

  const saveSelectedControl = async () => {
    isLoading(true)
    try {
      await axios({
        method: "post",
        url: `${process.env.GATSBY_API_BASE_URL}/order/select-boiler-control`,
        data: {
          orderId: order.orderId,
          boilerControlId: selectedControl.id,
        },
      })

      updateOrder((order) => ({
        ...order,
        steps: {
          ...order.steps,
          controls: {
            ...order.steps.controls,
            result: selectedControl.name,
            data: selectedControl,
          },
        },
      }))
      updateSteps()
      isLoading(false)
    } catch (error) {
      openSnackbar(handleBackendErrors(error, "save control"))
      isLoading(false)
    }
  }

  return (
    <>
      <div className="controls">
        {boilerControls.map(({ node }) => (
          <BoilerControl
            key={node.contentful_id}
            id={node.contentful_id}
            longDescription={node.longDescription.longDescription}
            name={node.name}
            photo={node.photo.fluid}
            price={node.price}
            selected={selectedControl.id === node.contentful_id}
            shortDescription={node.shortDescription}
            selectBoilerControl={() =>
              setSelectedControl({
                id: node.contentful_id,
                name: node.name,
                price: node.price,
              })
            }
          />
        ))}
      </div>
      <ButtonWithLoader
        additionalClasses="button-checkout"
        onClick={() => saveSelectedControl()}
        disabled={loading}
      >
        Enter your details
      </ButtonWithLoader>
    </>
  )
}
