import React from "react"
import { graphql } from "gatsby"
import axios from "axios"
import { Link } from "gatsby"
import SnackbarProvider from "react-simple-snackbar"

import {
  BoilerCheckout,
  BoilerCheckoutConfirmation,
} from "../../components/BoilerCheckout"
import { Loader, Seo } from "../../components/Shared"
import { quoteResultURL } from "../../utilities"

const BoilerQuoteCheckout = ({ data, location }) => {
  // Needed incase wrapper div isn't full height of screen
  React.useEffect(() => {
    // Making background light grey on mount
    document.body.classList.add("background-grey-lightest")
    return () => {
      // Resetting background colour on unmount so colour doesn't persist on future pages
      document.body.classList.remove("background-grey-lightest")
    }
  }, [])
  const standardControl = data.allContentfulBoilerControls.edges.find(
    ({ node }) => node.contentful_id
  ).node
  const standardFilter = data.allContentfulBoilerFilters.edges.find(
    ({ node }) => node.contentful_id
  ).node
  const [orderCompleted, setOrderCompleted] = React.useState(false)
  const [loader, setLoader] = React.useState({
    error: false,
    errorText: "",
    errorType: "",
    loading: true,
    text: "Setting up checkout",
  })

  const [order, updateOrder] = React.useState({
    quoteId: "",
    boilerId: "",
    orderId: "",
    orderNumber: "",
    boilerPrice: 0,
    boilerName: "",
    steps: {
      installDate: {
        number: 1,
        name: "Install date",
        description: "When would you like us to install your new boiler?",
        result: "",
        completed: false,
        disabled: false,
        data: {
          installDate: "",
        },
      },
      controls: {
        number: 2,
        name: "Controls",
        description:
          "How would you like to control your new boiler/central heating system?",
        result: "",
        completed: false,
        disabled: true,
        data: {
          id: "",
          imageUrl: "",
          name: standardControl.name,
          price: standardControl.price,
        },
      },
      filters: {
        number: 3,
        name: "Boiler filter",
        description:
          "Which filter?",
        result: "",
        completed: false,
        disabled: true,
        data: {
          id: "",
          imageUrl: "",
          name: standardFilter.name,
          price: standardFilter.price,
        },
      },
      details: {
        number: 3,
        name: "Your details",
        description: "What are your contact details?",
        result: "",
        completed: false,
        disabled: true,
        data: {
          email: "",
          homePhone: "",
          mobile: "",
          firstName: "",
          lastName: "",
        },
      },
      installAddress: {
        number: 4,
        name: "Install address",
        description: "Where are we installing your new boiler?",
        result: "",
        completed: false,
        disabled: true,
        data: {
          city: "",
          line1: "",
          line2: "",
          line3: "",
          postalCode: "",
          region: "",
        },
      },
      photos: {
        number: 5,
        name: "Photos",
        description:
          "(Optional) Can you show us what your existing boiler looks like?",
        result: "",
        completed: false,
        disabled: true,
        data: { urls: [] },
      },
      payment: {
        number: 6,
        name: "Payment",
        description:
          "You’re all set! How would you like to pay for your installation?",
        result: "",
        completed: false,
        disabled: true,
        data: {},
      },
    },
    boiler: {
      award: "",
      boilerType: "",
      brand: "",
      erpRating: "",
      features: [],
      fuelType: "",
      id: "",
      kW: 0,
      manufacturerCountry: "",
      model: "",
      name: "",
      photo: "",
      price: 0,
      shortDescription: "",
      tagline: "",
      warranty: 0,
    },
    quote: {
      bathtubs: "",
      bedrooms: "",
      boilerFlueExit: "",
      boilerMove: false,
      boilerSituation: "",
      combiConversion: false,
      currentBoilerType: "",
      flueCost: 0,
      fuelType: "",
      id: "",
      interestRate: 0,
      kind: "",
      labourCost: 0,
      minKw: 0,
      moreInfoNeeded: false,
      postcode: "",
      propertyType: "",
      quoteNumber: 0,
      radiators: 0,
      showers: 0,
      suggestedBoilers: [],
      vat: 0,
    },
  })

  const findBoiler = async (boilersFromQuote, boilerId) => {
    const boiler = boilersFromQuote.find(
      (boiler) => boiler.id === boilerId && boiler.price
    )
    return boiler
  }

  const createNewOrder = async (quoteId, boilerId) => {
    setLoader((loader) => ({
      ...loader,
      error: false,
      loading: true,
    }))

    try {
      const createOrder = await axios({
        method: "post",
        url: `${process.env.GATSBY_API_BASE_URL}/order/create`,
        data: {
          quoteId: quoteId,
          boilerId: boilerId,
        },
      })

      const newOrder = createOrder.data
      localStorage.setItem("orderId", newOrder.orderId)
      localStorage.setItem("orderNumber", newOrder.orderNumber)

      const boiler = await findBoiler(newOrder.quote.suggestedBoilers, boilerId)

      if (boiler === undefined) {
        throw "Could not find boiler"
      }

      updateOrder((order) => ({
        ...order,
        orderId: newOrder.orderId,
        orderNumber: newOrder.orderNumber,
        quoteId: quoteId,
        boilerId: boilerId,
        boiler: boiler,
        quote: newOrder.quote,
      }))

      setLoader((loader) => ({
        ...loader,
        error: false,
        loading: false,
      }))
    } catch (error) {
      updateOrder((order) => ({
        ...order,
        quoteId: quoteId,
        boilerId: boilerId,
      }))

      if (error === "Could not find boiler") {
        setLoader((loader) => ({
          ...loader,
          error: true,
          errorText: "Could not find boiler details",
          errorType: "create-order",
          loading: false,
        }))
      } else {
        setLoader((loader) => ({
          ...loader,
          error: true,
          errorText: "Could not set up order",
          errorType: "create-order",
          loading: false,
        }))
      }
    }
  }
  const getQuoteDetails = async (quoteId, boilerId) => {
    try {
      const getQuote = await axios({
        method: "get",
        url: `${process.env.GATSBY_API_BASE_URL}/quote/${quoteId}`,
      })

      const boiler = await findBoiler(getQuote.data.suggestedBoilers, boilerId)

      if (boiler === undefined) {
        throw "Could not find boiler"
      }

      updateOrder((order) => ({
        ...order,
        orderId: localStorage.getItem("orderId"),
        orderNumber: localStorage.getItem("orderNumber"),
        quoteId: quoteId,
        boilerId: boilerId,
        boiler: boiler,
        quote: getQuote.data,
      }))

      setLoader((loader) => ({
        ...loader,
        error: false,
        loading: false,
      }))
    } catch (error) {
      updateOrder((order) => ({
        ...order,
        quoteId: quoteId,
        boilerId: boilerId,
      }))

      if (error === "Could not find boiler") {
        setLoader((loader) => ({
          ...loader,
          error: true,
          errorText: "Could not find boiler details",
          errorType: "find-order",
          loading: false,
        }))
      } else {
        setLoader((loader) => ({
          ...loader,
          error: true,
          errorText: "Could not find existing order",
          errorType: "find-order",
          loading: false,
        }))
      }
    }
  }

  React.useEffect(() => {
    const locationArray = window.location.href.split("?quote=")
    const orderFromURL = locationArray[locationArray.length - 1].split(
      "&boiler="
    )

    if (
      localStorage.getItem("orderId") &&
      localStorage.getItem("orderNumber")
    ) {
      getQuoteDetails(orderFromURL[0], orderFromURL[1])
    } else {
      createNewOrder(orderFromURL[0], orderFromURL[1])
    }
  }, [])

  if (loader.loading || loader.error) {
    return (
      <>
        <Seo
          url={data.site && data.site.siteMetadata.url + "/quote/checkout"}
          title="Checkout | Home Assist Repairs and Servicing"
        />
        <Loader
          text={loader.text}
          error={loader.error}
          errorText={loader.errorText}
          errorButton={
            <div className="loader-buttons">
              {order.quoteId !== "" && (
                <Link
                  className="button-primary"
                  to={quoteResultURL(order.quoteId)}
                >
                  Change boiler
                </Link>
              )}
              <Link
                className={
                  order.quoteId !== "" ? "button-secondary" : "button-primary"
                }
                to="/quote"
              >
                Create new quote
              </Link>
            </div>
          }
        />
      </>
    )
  } else {
    return orderCompleted ? (
      <BoilerCheckoutConfirmation order={order} location={location} />
    ) : (
      <SnackbarProvider>
        <BoilerCheckout
          order={order}
          updateOrder={updateOrder}
          boilerControls={data.allContentfulBoilerControls.edges}
          boilerFilters={data.allContentfulBoilerFilters.edges}
          setOrderCompleted={setOrderCompleted}
        />
      </SnackbarProvider>
    )
  }
}

export default BoilerQuoteCheckout

export const pageQuery = graphql`
  query getAllBoilerControls {
    site {
      siteMetadata {
        url
      }
    }
    allContentfulBoilerControls(sort: { fields: createdAt }) {
      edges {
        node {
          price
          shortDescription
          contentful_id
          name
          photo {
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid
            }
            title
          }
          longDescription {
            longDescription
          }
        }
      }
    }
    allContentfulBoilerFilters(sort: { fields: createdAt }) {
      edges {
        node {
          contentful_id
          shortDescription {
            shortDescription
          }
          longDescription {
            longDescription
          }
          name
          price
          photo {
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid
            }
            title
          }
        }
      }
    }
  }
`
