import React from "react"

import { BreakDownTable, RangeSlider } from "../../../Shared"
import { StripeSubscriptionForm } from "../StripeSubscriptionForm"
import {
  calculateOrderPayments,
  convertPriceToPounds,
} from "../../../../utilities"

export const PayFinance = ({
  boilerAmount,
  controlAmount,
  flueCost,
  interesteRate,
  labourCost,
  orderId,
  setOrderCompleted,
  adminLineItems,
  updateOrder,
  vat,
}) => {
  const [loanTerm, setLoanTerm] = React.useState(24)

  const [boilerFinance, setBoilerFinance] = React.useState(() =>
    calculateOrderPayments({
      boilerAmount: parseInt(boilerAmount) * 100,
      boilerControlAmount: parseInt(controlAmount) * 100,
      code: "finance",
      financeMonths: loanTerm,
      interestRate: interesteRate,
      labourCost: parseInt(labourCost) * 100,
      flueCost: parseInt(flueCost) * 100,
      adminLineItems: adminLineItems,
      vat: vat,
    })
  )
  React.useEffect(() => {
    setBoilerFinance(() =>
      calculateOrderPayments({
        boilerAmount: parseInt(boilerAmount) * 100,
        boilerControlAmount: parseInt(controlAmount) * 100,
        code: "finance",
        financeMonths: loanTerm,
        interestRate: interesteRate,
        labourCost: parseInt(labourCost) * 100,
        flueCost: parseInt(flueCost) * 100,
        adminLineItems: adminLineItems,
        vat: vat,
      })
    )
  }, [loanTerm])

  return (
    <>
      <p>
        Pay for your boiler with 24 - 120 monthly direct debit instalments (
        {interesteRate}% APR Representative).
      </p>
      <p>Payments will be taken on the date of purchase each month.</p>
      <div className="loan-term">
        <div className={`loan-term-header loan-term-header-no-slider`}>
          <span>Loan term</span>
          <span className="loan-term-value">{loanTerm} months</span>
        </div>
        <RangeSlider
          value={loanTerm}
          updateValue={(value) => setLoanTerm(value)}
          minValue={24}
          maxValue={120}
        />
      </div>
      <BreakDownTable
        rows={[
          {
            id: "1",
            label: "Pay now",
            price: convertPriceToPounds(boilerFinance.upfrontAmount),
          },
          {
            id: "2",
            label: "Loan amount",
            price: convertPriceToPounds(boilerFinance.financeAmount),
          },
          {
            id: "3",
            label: "Interest",
            price: convertPriceToPounds(boilerFinance.financeCharge),
          },
          {
            id: "4",
            label: "(Monthly payment)",
            price: convertPriceToPounds(boilerFinance.monthlyRepayment),
          },
          {
            id: "5",
            label: "Total",
            price: convertPriceToPounds(boilerFinance.totalRepayment),
          },
        ]}
      />
      <StripeSubscriptionForm
        paymentCode="finance"
        orderId={orderId}
        setOrderCompleted={setOrderCompleted}
        financeMonths={loanTerm.toString()}
        financeData={boilerFinance}
        updateOrder={updateOrder}
      />
    </>
  )
}
