import React from "react"
import PropTypes from "prop-types"

export const PaymentOption = ({
  children,
  name,
  openOption,
  setOpenOption,
}) => {
  return (
    <section
      className={`payment-option${openOption === name ? " active" : ""}`}
    >
      <button
        className="payment-option-header"
        onClick={() => setOpenOption(name)}
      >
        <span
          className={`payment-option-radio-button${
            openOption === name ? " active" : ""
          }`}
        />
        <h3 className="payment-option-heading">{name}</h3>
      </button>
      <div className="payment-option-body">
        <div className="payment-option-body-contents">
          {openOption === name && children}
        </div>
      </div>
    </section>
  )
}

PaymentOption.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  openOption: PropTypes.string.isRequired,
  setOpenOption: PropTypes.func.isRequired,
}
