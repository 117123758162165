import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { PaymentOption } from "./PaymentOption"
import { PayNow } from "./PayNow"
import { PayHalfUpFront } from "./PayHalfUpFront"
import { PayFinance } from "./PayFinance"

export const stripeOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#222",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#999",
      },
    },
    invalid: {
      color: "#d62b62",
      iconColor: "#fa755a",
    },
  },
}

export const Payment = ({
  boilerAmount,
  boilerUpFrontCosts,
  controlAmount,
  flueCost,
  interesteRate,
  labourCost,
  orderId,
  setOrderCompleted,
  updateOrder,
  userEmail,
  adminLineItems,
  vat,
}) => {
  const [openOption, setOpenOption] = React.useState("")
  const [stripe] = React.useState(() =>
    loadStripe(process.env.GATSBY_STRIPE_PK)
  )

  return (
    <div className="payment-options">
      <PaymentOption
        name="Pay by card"
        openOption={openOption}
        setOpenOption={setOpenOption}
      >
        <Elements stripe={stripe}>
          <PayNow
            adminLineItems={adminLineItems}
            boilerUpFrontCosts={boilerUpFrontCosts}
            orderId={orderId}
            setOrderCompleted={setOrderCompleted}
            updateOrder={updateOrder}
            userEmail={userEmail}
          />
        </Elements>
      </PaymentOption>
      <PaymentOption
        name="50% up-front"
        openOption={openOption}
        setOpenOption={setOpenOption}
      >
        <Elements stripe={stripe}>
          <PayHalfUpFront
            adminLineItems={adminLineItems}
            boilerAmount={boilerAmount}
            controlAmount={controlAmount}
            flueCost={flueCost}
            labourCost={labourCost}
            orderId={orderId}
            setOrderCompleted={setOrderCompleted}
            updateOrder={updateOrder}
            vat={vat}
          />
        </Elements>
      </PaymentOption>
      <PaymentOption
        name="Pay monthly"
        openOption={openOption}
        setOpenOption={setOpenOption}
      >
        <Elements stripe={stripe}>
          <PayFinance
            adminLineItems={adminLineItems}
            boilerAmount={boilerAmount}
            controlAmount={controlAmount}
            flueCost={flueCost}
            interesteRate={interesteRate}
            labourCost={labourCost}
            orderId={orderId}
            setOrderCompleted={setOrderCompleted}
            updateOrder={updateOrder}
            vat={vat}
          />
        </Elements>
      </PaymentOption>
    </div>
  )
}
