import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"

import {
  ButtonWithLoader,
  PhotoUploader,
  TextArea,
  Form,
  SnackBarOptions,
} from "../../Shared"
import { handleBackendErrors } from "../../../utilities"

export const UploadPhotos = ({ updateSteps, order, updateOrder }) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const [photos, setPhotos] = React.useState([])

  const savePhotos = async (values, setSubmitting) => {
    let formData = new FormData()
    formData.append("orderId", order.orderId)
    formData.append("note", values.notes)
    photos.forEach((photo) => {
      formData.append("files", photo.file)
    })

    try {
      const uploadPhotos = await axios({
        method: "post",
        url: `${process.env.GATSBY_API_BASE_URL}/order/upload-images`,
        data: formData,
      })

      updateOrder((order) => ({
        ...order,
        steps: {
          ...order.steps,
          photos: {
            ...order.steps.photos,
            result: uploadPhotos.data.urls.length + " uploaded",
            data: {
              ...uploadPhotos.data.urls,
            },
          },
        },
      }))

      updateSteps()
    } catch (error) {
      openSnackbar(handleBackendErrors(error, "upload photos"))
    }
    setSubmitting(false)
  }

  return (
    <div className="upload-photos">
      <Formik
        initialValues={{
          notes: "",
        }}
        validationSchema={Yup.object().shape({
          notes: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          savePhotos(values, setSubmitting)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form">
              <PhotoUploader photos={photos} updatePhotos={setPhotos} />
              <div className="upload-photos-notes">
                <TextArea
                  label="Additional notes"
                  name="notes"
                  optional={true}
                />
              </div>
            </div>
            <ButtonWithLoader
              additionalClasses="button-checkout"
              disabled={isSubmitting}
              type="submit"
            >
              Choose how to pay
            </ButtonWithLoader>
          </Form>
        )}
      </Formik>
    </div>
  )
}
