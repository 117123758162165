import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"

import {
  ButtonWithLoader,
  Form,
  FormSection,
  Input,
  SnackBarOptions,
} from "../../Shared"
import { defaultRequiredText } from "../../../constants"
import { handleBackendErrors } from "../../../utilities"

export const Details = ({ order, updateOrder, updateSteps }) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)

  const saveUserDetails = async (values, setSubmitting) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.GATSBY_API_BASE_URL}/order/add-user-details`,
        data: {
          orderId: order.orderId,
          userDetails: values,
        },
      })

      updateOrder((order) => ({
        ...order,
        steps: {
          ...order.steps,
          details: {
            ...order.steps.details,
            result: values.firstName + " " + values.lastName,
            data: values,
          },
        },
      }))
      updateSteps()
    } catch (error) {
      openSnackbar(handleBackendErrors(error, "save details"))
    }
    setSubmitting(false)
  }

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          homePhone: "",
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(defaultRequiredText),
          lastName: Yup.string().required(defaultRequiredText),
          email: Yup.string()
            .email("Must be a valid email address")
            .required(defaultRequiredText),
          mobile: Yup.string().required(defaultRequiredText),
          homePhone: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          saveUserDetails(values, setSubmitting)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form">
              <FormSection>
                <Input name="firstName" label="First name" />
                <Input name="lastName" label="Last name" />
                <Input name="email" label="Your email" />
                <Input name="mobile" label="Mobile" />
                <Input name="homePhone" label="Home phone" optional={true} />
              </FormSection>
            </div>
            <ButtonWithLoader
              additionalClasses="button-checkout"
              type="submit"
              disabled={isSubmitting}
            >
              Enter your address
            </ButtonWithLoader>
          </Form>
        )}
      </Formik>
    </>
  )
}
