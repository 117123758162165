import React from "react"
import PropTypes from "prop-types"

import {
  CheckoutBreakdown,
  CheckoutStep,
  CheckoutStepsContainer,
  CheckoutToolbar,
  CheckoutToolbarItem,
  NavCheckout,
} from "../Shared"
import {
  quoteResultURL,
  calculateOrderPayments,
  convertPriceToPounds,
} from "../../utilities"

import { BoilerPhotoExamplesModal, EmailQuote } from "../BoilerQuote"
import { InstallDate } from "./InstallDate"
import { Controls } from "./Controls"
import { Filters } from "./Filters"
import { Details } from "./Details"
import { InstallAddress } from "./InstallAddress"
import { UploadPhotos } from "./UploadPhotos"
import { Payment } from "./Payment"

export { BoilerCheckoutConfirmation } from "./Confirmation"

export const BoilerOrderContext = React.createContext()

export const BoilerCheckout = ({
  order,
  updateOrder,
  boilerControls,
  boilerFilters,
  setOrderCompleted,
}) => {
  const [activeStep, setActiveStep] = React.useState(1)
  const [
    boilerPhotoExamplesIsVisible,
    setBoilerPhotoExamplesIsVisible,
  ] = React.useState(false)
  const [emailQuoteVisible, setEmailQuoteVisibility] = React.useState(false)
  const [breakdownVisibleMobile, setBreakdownVisibleMobile] = React.useState(
    false
  )

  const [boilerUpFrontCosts, setBoilerUpFrontCosts] = React.useState(() =>
    calculateOrderPayments({
      boilerAmount: parseInt(order.boiler.price) * 100,
      boilerControlAmount: parseInt(order.steps.controls.data.price) * 100,
      code: "up-front",
      financeMonths: 0,
      interestRate: 0,
      labourCost: parseInt(order.quote.labourCost) * 100,
      flueCost: parseInt(order.quote.flueCost) * 100,
      adminLineItems: order.quote.lineItems,
      vat: order.quote.vat,
    })
  )
  React.useEffect(() => {
    setBoilerUpFrontCosts(() =>
      calculateOrderPayments({
        boilerAmount: parseInt(order.boiler.price) * 100,
        boilerControlAmount: parseInt(order.steps.controls.data.price) * 100,
        code: "up-front",
        financeMonths: 0,
        interestRate: 0,
        labourCost: parseInt(order.quote.labourCost) * 100,
        flueCost: parseInt(order.quote.flueCost) * 100,
        adminLineItems: order.quote.lineItems,
        vat: order.quote.vat,
      })
    )
  }, [order])

  const updateSteps = (step, nextStep, nextStepNumber) => {
    // Get quote again

    updateOrder((order) => ({
      ...order,
      steps: {
        ...order.steps,
        [step]: {
          ...order.steps[step],
          completed: true,
        },
        [nextStep]: {
          ...order.steps[nextStep],
          disabled: false,
        },
      },
    }))

    setActiveStep(nextStepNumber)

    window.scrollTo({
      top: 0,
    })
  }

  const formatOrderExtras = (contentfulOrderExtras) => {
    let formattedExtras = []

    contentfulOrderExtras.map((extra, index) =>
      formattedExtras.push({
        id: (5 + index).toString(),
        label: extra,
        price: 0,
      })
    )
    return formattedExtras
  }

  const formatOrderLineItems = (adminLineItems) => {
    let formattedExtras = []
    adminLineItems &&
      adminLineItems.length > 0 &&
      adminLineItems.map((lineItem) =>
        formattedExtras.push({
          id: lineItem.id,
          label: lineItem.description,
          price: lineItem.price,
        })
      )
    return formattedExtras
  }
  return (
    <BoilerOrderContext.Provider value={order}>
      <div className="background-grey-lightest">
        <NavCheckout
          breakdownIcon="order"
          breakdownText="Your install"
          setBreakdownVisibleMobile={() => setBreakdownVisibleMobile(true)}
        />
        <CheckoutToolbar
          steps={order}
          activeStep={activeStep}
          totalSteps={Object.keys(order.steps).length}
        >
          <CheckoutToolbarItem
            activeStep={activeStep}
            step={order.steps.installDate}
            setActiveStep={setActiveStep}
          />
          <CheckoutToolbarItem
            activeStep={activeStep}
            step={order.steps.controls}
            setActiveStep={setActiveStep}
          />
          <CheckoutToolbarItem
            activeStep={activeStep}
            step={order.steps.details}
            setActiveStep={setActiveStep}
          />
          <CheckoutToolbarItem
            activeStep={activeStep}
            step={order.steps.installAddress}
            setActiveStep={setActiveStep}
          />
          <CheckoutToolbarItem
            activeStep={activeStep}
            step={order.steps.photos}
            setActiveStep={setActiveStep}
          />
          <CheckoutToolbarItem
            activeStep={activeStep}
            step={order.steps.payment}
            setActiveStep={setActiveStep}
            chevron={false}
          />
        </CheckoutToolbar>
        <CheckoutStepsContainer>
          <CheckoutStep
            activeStep={activeStep}
            step={order.steps.installDate}
            setActiveStep={setActiveStep}
          >
            <InstallDate
              updateOrder={updateOrder}
              updateSteps={() =>
                updateSteps(
                  "installDate",
                  "controls",
                  order.steps.controls.number
                )
              }
              nextStep="controls"
              order={order}
            />
          </CheckoutStep>
          <CheckoutStep
            activeStep={activeStep}
            step={order.steps.controls}
            setActiveStep={setActiveStep}
          >
            <Controls
              boilerControls={boilerControls}
              updateOrder={updateOrder}
              order={order}
              updateSteps={() =>
                updateSteps("controls", "details", order.steps.details.number)
              }
            />
          </CheckoutStep>
          {/*           <CheckoutStep>
            <Filters boilerFilters={boilerFilters} />
          </CheckoutStep> */}
          <CheckoutStep
            activeStep={activeStep}
            step={order.steps.details}
            setActiveStep={setActiveStep}
          >
            <Details
              order={order}
              updateOrder={updateOrder}
              updateSteps={() =>
                updateSteps(
                  "details",
                  "installAddress",
                  order.steps.installAddress.number
                )
              }
            />
          </CheckoutStep>
          <CheckoutStep
            activeStep={activeStep}
            step={order.steps.installAddress}
            setActiveStep={setActiveStep}
          >
            <InstallAddress
              order={order}
              updateOrder={updateOrder}
              updateSteps={() =>
                updateSteps(
                  "installAddress",
                  "photos",
                  order.steps.photos.number
                )
              }
            />
          </CheckoutStep>
          <CheckoutStep
            activeStep={activeStep}
            step={order.steps.photos}
            setActiveStep={setActiveStep}
            stepDescriptionButton={
              <button
                onClick={() => setBoilerPhotoExamplesIsVisible(true)}
                type="button"
                className="link-button"
              >
                View examples
              </button>
            }
          >
            <UploadPhotos
              updateOrder={updateOrder}
              order={order}
              updateSteps={() =>
                updateSteps("photos", "payment", order.steps.payment.number)
              }
            />
            <BoilerPhotoExamplesModal
              isVisible={boilerPhotoExamplesIsVisible}
              dismissFunc={() => setBoilerPhotoExamplesIsVisible(false)}
            />
          </CheckoutStep>
          <CheckoutStep
            activeStep={activeStep}
            step={order.steps.payment}
            setActiveStep={setActiveStep}
          >
            <Payment
              boilerUpFrontCosts={boilerUpFrontCosts}
              controlAmount={order.steps.controls.data.price}
              labourCost={order.quote.labourCost}
              flueCost={order.quote.flueCost}
              vat={order.quote.vat}
              boilerAmount={order.boiler.price}
              interesteRate={order.quote.interestRate}
              setOrderCompleted={setOrderCompleted}
              updateOrder={updateOrder}
              userEmail={order.steps.details.data.email}
              adminLineItems={order.quote.lineItems}
              orderId={order.orderId}
            />
          </CheckoutStep>
        </CheckoutStepsContainer>
        <CheckoutBreakdown
          changeLinkUrl={quoteResultURL(order.quoteId)}
          isVisibleMobile={breakdownVisibleMobile}
          dismissFunc={() => setBreakdownVisibleMobile(false)}
          heading="Your install"
          rows={[
            {
              id: "1",
              label: `${order.boiler.name} Boiler (${order.boiler.warranty} year warranty)`,
              price: convertPriceToPounds(
                boilerUpFrontCosts.baseCostNoControls
              ),
            },
            {
              id: "2",
              label: `${order.steps.controls.data.name} controls`,
              price: convertPriceToPounds(
                order.steps.controls.data.price * 100
              ),
            },
            {
              id: "3",
              label: "Old boiler removal",
              price: 0,
            },
            {
              id: "4",
              label: "New boiler installation",
              price: 0,
            },
          ]
            .concat(formatOrderExtras(order.quote.orderExtras))
            .concat(formatOrderLineItems(order.quote.lineItems))}
          total={convertPriceToPounds(boilerUpFrontCosts.upfrontAmount)}
        >
          <div className="boiler-checkout-breakdown-buttons">
            <button
              className="boiler-checkout-save-quote-button"
              onClick={() => setEmailQuoteVisibility(true)}
            >
              Save your quote for later
            </button>
          </div>
        </CheckoutBreakdown>
        {order && order.quote && order.quote.id && (
          <EmailQuote
            isVisible={emailQuoteVisible}
            dismissFunc={() => setEmailQuoteVisibility(false)}
            quoteId={order.quote.id}
          />
        )}
      </div>
    </BoilerOrderContext.Provider>
  )
}

BoilerCheckout.propTypes = {
  boilerControls: PropTypes.array.isRequired,
}
