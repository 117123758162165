import React from "react"
import dayjs from "dayjs"
import { Link } from "gatsby"

import {
  ConfirmationPage,
  ConfirmationPageSection,
  Layout,
  Receipt,
  BreakDownTable,
  DataDisplay,
  Seo,
} from "../../Shared"
import {
  convertToDateWithSuffix,
  convertPriceToPounds,
} from "../../../utilities"

import BlobWide from "../../../images/Blob-wide.svg"

export const BoilerCheckoutConfirmation = ({ order, location }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const payment = order.steps.payment.data && order.steps.payment.data
  const address =
    order.steps.installAddress.data && order.steps.installAddress.data
  const user = order.steps.details.data && order.steps.details.data

  return (
    <Layout minimal={true}>
      <Seo
        url={location && location.href ? location.href : null}
        title="Installation confirmed | Home Assist Repairs and Servicing"
      />
      <ConfirmationPage
        heading="Installation confirmed"
        followUp={`Installation ${convertToDateWithSuffix(
          dayjs(order.steps.installDate.data.installDate).format("D")
        )} ${dayjs(order.steps.installDate.data.installDate).format(
          "MMMM"
        )} (9am - 5pm)`}
        note="We'll contact you the day before to plan our arrival time"
      >
        <ConfirmationPageSection>
          <div>
            <div className="confirmation-boiler">
              <BlobWide />
              <img
                className="confirmation-boiler-photo"
                src={order.boiler.photo}
                alt={order.boiler.name}
                title={order.boiler.name}
              />
              <img
                className="confirmation-boiler-photo"
                src={order.boiler.photo}
                alt={order.boiler.name}
                title={order.boiler.name}
              />
              {order.boiler.award && (
                <img
                  className="confirmation-boiler-award"
                  src={order.boiler.award}
                  alt="Award winning boiler"
                  title="Award winning boiler"
                />
              )}
            </div>
          </div>
          <div>
            <h2 className="heading-small">Order #{order.orderNumber}</h2>
            <p style={{ marginTop: "0.5rem" }}>
              Confirmation has been sent to your email
            </p>
            <Receipt
              rows={[
                `${order.boiler.name} (${order.boiler.warranty} year warranty)`,
                `${order.steps.controls.data.name} thermostat`,
                "Old boiler removal",
                "New boiler installation",
              ].concat(order.quote.orderExtras)}
            />
            {payment.code === "finance" ? (
              <>
                <BreakDownTable
                  rows={[
                    {
                      id: "1",
                      label: "Paid",
                      price: convertPriceToPounds(payment.upfrontAmount),
                    },
                    {
                      id: "2",
                      label: "Loan amount",
                      price: convertPriceToPounds(payment.financeAmount),
                    },
                    {
                      id: "3",
                      label: "Interest",
                      price: convertPriceToPounds(payment.financeCharge),
                    },
                    {
                      id: "4",
                      label: `Monthly payments (${payment.months})`,
                      price: convertPriceToPounds(payment.monthlyRepayment),
                    },
                    {
                      id: "5",
                      label: "Total",
                      price: convertPriceToPounds(payment.totalRepayment),
                    },
                  ]}
                />
                <p style={{ marginBottom: "0.75rem" }}>
                  Final monthly payment:{" "}
                  {convertToDateWithSuffix(
                    dayjs(payment.cancellationDateISO).format("D")
                  )}{" "}
                  {dayjs(payment.cancellationDateISO).format("MMMM YYYY")}
                </p>
                <p>
                  Payments will be taken on the date of purchase each month.
                </p>
              </>
            ) : payment.code === "50-percent-upfront" ? (
              <>
                <BreakDownTable
                  rows={[
                    {
                      id: "1",
                      label: "Paid",
                      price: convertPriceToPounds(payment.upfrontAmount),
                    },
                    {
                      id: "2",
                      label: "Loan amount",
                      price: convertPriceToPounds(payment.financeAmount),
                    },
                    {
                      id: "3",
                      label: "Interest",
                      price: 0,
                    },
                    {
                      id: "4",
                      label: `Monthly payments (${payment.months})`,
                      price: convertPriceToPounds(payment.monthlyRepayment),
                    },
                    {
                      id: "5",
                      label: "Total",
                      price: convertPriceToPounds(payment.totalRepayment),
                    },
                  ]}
                />
                <p style={{ marginBottom: "0.75rem" }}>
                  Final monthly payment:{" "}
                  {convertToDateWithSuffix(
                    dayjs(payment.cancellationDateISO).format("D")
                  )}{" "}
                  {dayjs(payment.cancellationDateISO).format("MMMM YYYY")}
                </p>
                <p>
                  Payments will be taken on the date of purchase each month.
                </p>
              </>
            ) : (
              <BreakDownTable
                rows={[
                  {
                    id: "1",
                    label: "Paid",
                    price: convertPriceToPounds(payment.upfrontAmount),
                  },
                ]}
              />
            )}
          </div>
        </ConfirmationPageSection>
        <ConfirmationPageSection>
          <div className="confirmation-page-section-full-width">
            <h3 className="heading-small">Don’t lose your warranty</h3>
            <p>
              To keep your warranty your boiler must be serviced annually. Cover
              your boiler today with a Home Assist cover plan.
            </p>
            <Link to="/home-cover-plans" className="button-primary">
              Cover your boiler
            </Link>
          </div>
        </ConfirmationPageSection>
        <ConfirmationPageSection>
          <div className="confirmation-page-section-full-width">
            <h3 className="heading-small">Installation details</h3>
            <p>
              Installation:{" "}
              {convertToDateWithSuffix(
                dayjs(order.steps.installDate.data.installDate).format("D")
              ) +
                " " +
                dayjs(order.steps.installDate.data.installDate).format(
                  "MMMM YYYY"
                )}
            </p>
          </div>
          <DataDisplay label="Contact">
            {user ? (
              <>
                {user.firstName && user.lastName && (
                  <>
                    {user.firstName + " " + user.lastName}
                    <br />
                  </>
                )}
                {user.email && (
                  <>
                    {user.email}
                    <br />
                  </>
                )}
                {user.mobile && (
                  <>
                    {user.mobile}
                    <br />
                  </>
                )}
                {user.homePhone && (
                  <>
                    {user.homePhone}
                    <br />
                  </>
                )}
              </>
            ) : (
              "No details found"
            )}
          </DataDisplay>
          <DataDisplay label="Address">
            {address ? (
              <>
                {address.line1 && (
                  <>
                    {address.line1},<br />
                  </>
                )}
                {address.line2 && (
                  <>
                    {address.line2},<br />
                  </>
                )}
                {address.city && (
                  <>
                    {address.city},<br />
                  </>
                )}
                {address.region && (
                  <>
                    {address.region},<br />
                  </>
                )}
                {address.postalCode && address.postalCode}
              </>
            ) : (
              "No address found"
            )}
          </DataDisplay>
        </ConfirmationPageSection>
      </ConfirmationPage>
    </Layout>
  )
}
